<template>
  <v-card class="mt-5">
    <v-toolbar
      flat
      dense
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multiproject.resourcesSelection.title')" />
      <v-spacer />
    </v-toolbar>

    <v-card-text class="pt-4 px-12">
      <v-item-group v-model="selected" multiple>
        <v-row>
          <v-col
            v-for="resource in game.resourceList"
            :key="`resource-${resource.color}`"
            cols="12"
            md="6"
          >
            <v-item
              v-slot:default="{ active, toggle }"
              :value="resource.color"
            >
              <v-card
                dark
                :color="$options.colors[resource.color]"
                :flat="!active"
                :elevation="active ? 24 : 0"
                class="d-flex align-center"
                height="45"
                @click="toggle"
              >
                <v-overlay v-if="!active" absolute />
                <div class="headline flex-grow-1 text-center" v-text="resource.name" />
                <v-icon
                  large
                  class="mr-4"
                  v-text="active ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'"
                />
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn
        :loading="loadingCheckResources"
        :disabled="!selected.length || loadingCheckResources"
        color="accent"
        @click="checkResources"
        v-text="$t('multiproject.resourcesSelection.confirmButton')"
      />
      <v-spacer />
    </v-card-actions>

    <v-dialog v-model="isErrorModalVisible" max-width="600px">
      <v-card>
        <v-toolbar
          flat
          dense
          dark
          color="primary"
        >
          <v-spacer />
          <v-toolbar-title v-text="$t('multiproject.resourcesSelection.errorTitle')" />
          <v-spacer />
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-alert
            class="my-4"
            text
            type="error"
            v-text="selectionInvalidMessage"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="accent"
            @click="isErrorModalVisible = false"
            v-text="$t('multiproject.resourcesSelection.fixSelectionButton')"
          />
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import _difference from 'lodash/difference'

import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'ResourcesSelectionContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    selected: [],
    isErrorModalVisible: false,
    loadingCheckResources: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    currentTasksResources () {
      return this.currentPlayer.gantt
        .find(tasks => tasks.some(task => task.done < task.size))
        .filter(task => task.done < task.size)
        .map(task => task.resource)
    },
    selectionInvalidMessage () {
      if (_difference(this.currentTasksResources, this.selected).length) {
        return this.$t('multiproject.resourcesSelection.resourceMissing')
      }

      if (_difference(this.selected, this.currentTasksResources).length) {
        return this.$t('multiproject.resourcesSelection.resourceUnnecessary')
      }

      return null
    },
  },
  methods: {
    async checkResources () {
      if (this.selectionInvalidMessage) {
        this.isErrorModalVisible = true
      }
      else {
        this.loadingCheckResources = true
        await this.sendPlayerAction(AVAILABLE_ACTIONS.claimResources)
        this.loadingCheckResources = false
      }
    },
  },
  colors: {
    red: 'red',
    green: 'green',
    yellow: 'amber',
    blue: 'indigo',
  },
}
</script>
